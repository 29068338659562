
import { ROOT_ACTIONS } from '@/store/actions';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

import { Timestamp, addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { StateType } from '@/types';
import { User } from '@/store/user/types';
import { USER_GETTERS } from '@/store/user/getters';

@Component
export default class ResumeProgram extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) programId!: string

  comment = '';

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async submit() {
    try {
      await addDoc(collection(firestore, 'program-states'), {
        type: StateType.RESUMED,
        comment: this.comment,
        date: Timestamp.fromDate(new Date()),
        programId: this.programId,
        user: this.$store.getters[USER_GETTERS.ACTING_USER].id
      })

      await updateDoc(doc(firestore, 'programs', this.programId), {
        state: StateType.RESUMED
      })

      this.show = false;
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Programma hervat'
      })
      this.$emit('resumed')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }

} 
